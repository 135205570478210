/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8021e548-3aae-4956-b9b8-fc50d386361f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_K1sggbivn",
    "aws_user_pools_web_client_id": "68u9blaer3r72qjo3tqnsrmoma",
    "oauth": {}
};


export default awsmobile;
